
import {createRouter, createWebHistory} from 'vue-router'
import agreement from '../../src/view/agreement';
import policy from '../../src/view/policy';
import BasalPolicy from '../../src/view/basalPolicy'
 
const router = createRouter({
  history: createWebHistory(),     //路由模式
  routes: [
    {
      path: '/',
      name: 'Policy',
      component: policy,
      meta: { title: '隐私政策' }
    },
    {
      path: '/agreement',
      name: 'Agreement',
      component: agreement,
      meta: { title: '服务协议' }
    },
    {
      path: '/basalPolicy',
      name: 'BasalPolicy',
      component: BasalPolicy,
      meta: { title: '隐私政策' }
    },
    {
      path: '/basalAgreement',
      name: 'BasalAgreement',
      component: agreement,
      meta: { title: '服务协议' }
    }
  ]
})

export default router