
<template>
  <div class="main">
    <div class="" v-html="content1"></div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'PolicyText',
  data() {
    return {
      title: "隐私政策",
      content1: `<p class="content">
		<p  style="margin-top:12px;margin-bottom:12px;line-height:22px;text-align: center;font-size: 20px;font-weight: bold">儿童感统测评系统用户隐私政策</p>
		<p>儿童感统测评系统(下称“Basal测评”或“我们”)重视保护用户(下称“您”)的隐私。Basal测评依据《Basal测评隐私政策》(下称“本政策”)的约定为用户提供服务，本政策在用户和Basal测评间具有合同上的法律效力。</p>
		<p>在此特别提醒用户认真阅读、充分理解本政策各条款，特别是其中所涉及的免除、限制Basal测评责任的条款、对用户权利限制条款等。为了特别提示您限制、免责条款以及涉及到您的权益的等内容，这些条款可能以字体加粗形式提示您注意。</p>
		<p>请用户审慎阅读本政策(未成年人应在监护人陪同下阅读)。当您主动勾选、主动点击、主动填写同意《Basal测评隐私政策》时，即表示您已同意我们按照本政策收集、储存、使用和分享您的信息。</p>
		<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
        <strong><span style="font-family: 微软雅黑"><span style="font-family:微软雅黑">我们的产品基于DCloud uni-app(5+ App/Wap2App)开发，应用运行期间需要收集您的设备唯一识别码（IMEI/android ID/DEVICE_ID/IDFA、SIM 卡 IMSI 信息、OAID）以提供统计分析服务，并通过应用启动数据及异常错误日志分析改进性能和用户体验，为用户提供更好的服务。详情内容请访问《DCloud用户服务条款》。（DCloud用户服务条款超链至：<a style="color: #FF6B18" href="https://ask.dcloud.net.cn/protocol.html">https://ask.dcloud.net.cn/protocol.html</a>）</span></span></strong>
    </p>
    <p>《Basal测评隐私政策》正文</p>
		<p>本版发布日期：2021年12月11日</p>
		<p>本版生效日期：2021年12月15日</p>
		<p>本版更新日期：2021年12月15日</p>
		<p>引言</p>
		<p>在您使用Basal测评服务时，我们可能会收集、储存、使用和分享您的相关个人信息。我们通过本政策向您说明，我们如何收集、储存、使用和分享这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。</p>
		<p>基于国家相关法律法规的规定，以及为向您提供服务及提升服务质量、向您提供安全、顺畅、高效和个性化的体验的目的(包括支持我们开发新产品或完善已有产品功能，为您提供和推荐更为优质或适合的服务)，我们按照本政策收集储存、使用和分享您的信息。若您向我们提供了本政策中列明的信息，您可享受更优质、更便捷的服务，让我们更好地保护您的账户及资金安全。本政策将帮助您了解以下内容：</p>
	
		<p><strong>一、我们可能如何收集您的个人信息及收集信息的范围</strong></p>
		<p><strong>二、我们可能如何使用您的个人信息</strong></p>
		<p><strong>三、我们如何使用 Cookie和同类技术</strong></p>
		<p><strong>四、我们可能如何委托处理、共享、转让、公开披露您的个人信息</strong></p>
		<p><strong>五、我们如何保护和存储您的个人信息</strong></p>
		<p><strong>六、您的权利</strong></p>
		<p><strong>七、我们如何处理未成年人的个人信息</strong></p>
		<p><strong>八、本政策如何更新</strong></p>
		<p><strong>九、如何联系我们</strong></p>
		<p><strong>十、本政策适用及其他</strong></p>
		<p>个人信息定义：根据中华人民共和国国家标准《信息安全技术个人信息安全规范》，个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，如姓名、出生日期、身份证件号码、个人生物识别信息、住址、通信通讯联系方式、通信记录和内容、账号密码、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息等。个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。通常情况下，14岁以下（含）儿童的个人信息和自然人的隐私信息属于个人敏感信息。</p>
		<p><strong>一、我们可能如何收集您的个人信息及收集信息的范围</strong></p>
		<p>1.我们如何收集您的信息</p>
		<p>为了遵守国家相关法律法规的规定，以及为向您提供服务及提升服务质量、向您提供安全、顺畅、高效和个性化的体验的目的，我们会在您使用Basal测评业务功能时收集您主动输入或因使用服务而产生的信息，具体如下：</p>
		<p>（1）当您开通Basal测评账号服务时，为了帮助您完成开通，保护您账号的安全并满足相关法律法规的网络实名制要求，我们会收集您的账号别名、手机号码。若您不提供这类信息，您可能无法正常使用我们的服务。</p>
		<p>（2）当您使用Basal测评的测评时，为了帮助测评师准确判断你的个人情况，我们会收集您的姓名或昵称、真实年龄、性别、职业、绑定手机号、家庭情况及病例信息等。无法收集此类信息将可能影响为您提供正常服务或信息传递。</p>
		<p>（3）当您使用Basal测评消息推送服务时，我们会收集您的如下系统信息：设备型号、设备名称、操作系统、设备唯一设备标识符、登录IP地址、接入网络的方式、类型和状态、网络质量数据、操作日志、服务日志信息。这类信息是消息推送服务必须收集的基础信息，并且该服务依赖于第三方推送服务商，我们会把这些信息共享给服务厂商以便实现该服务。</p>
		<p>（4）为了提升服务品质，我们的某些服务将由我们的合作方、供应商提供。</p>
		<p>2.您使用我们提供的服务时，我们系统可能通过SDK或其他方式自动采集以下信息</p>
		<p>1）Basal测评可能会使用具有相应业务资质及能力的第三方服务商提供的软件服务工具包（简称“SDK”）来为您提供服务，但相关信息仅保留在Basal测评内部，不会被第三方服务商获取。</p>
		<p>（2）在某些特定使用场景下，由第三方服务商通过软件服务工具包（简称“SDK”）直接采集必要信息以完成服务。</p>
		<p>3.当您使用Basal测评服务时，在以下情形中，您可选择是否授权我们收集、使用您的个人信息：</p>
		<p>1）相机（摄像头）：用于修改个人头像信息。</p>
		<p>使用频率：触发图像上传业务时获取。</p>
		<p>2）地理位置：获取您所在地理位置，主要用于校验使用位置是否合规。</p>
		<p>使用频率：触发相关业务时获取。</p>
		<p>3）网络通讯：用于与服务端进行通讯。拒绝授权后，Basal测评所有功能无法使用。系统后台保存客户发生数据交换时所使用设备的网络信息，包括IP、端口信息。</p>
		<p>使用频率：触发网络服务时获取。</p>
		<p>4）相册：用于修改个人头像信息。</p>
		<p>使用频率：触发相关业务时获取。</p>
		<p>5）存储：涉及读写文件系统业务，比如应用信息、测评数据文件缓存、读取等。</p>
		<p>使用频率：触发相关业务时获取。</p>
		<p>上述功能可能需要您在您的设备中向我们开启相对应的系统访问权限，以实现这些功能所涉及的信息的收集和使用。请您注意，您开启这些权限即代表您授权我们可以收集和使用这些信息来实现上述功能，如您取消了这些授权，则我们将不再继续收集和使用您的这些信息，也无法为您提供上述与这些授权所对应的功能。如在上述情形之外收集或使用您的信息，我们会事先征求您的同意，并向您充分告知信息收集和使用的目的、方式和范围。</p>
		<p>如Basal测评为了提供附加功能，需要收集您更多的个人信息时，会向您说明收集的必要性，征求您的同意；若您拒绝提供，Basal测评将不予提供相应的附加功能。</p>
		<p>4.我们可能会通过以下方式和途径收集您的信息</p>
		<p>（1）当您使用Basal测评服务时，我们会收集您通过计算机、手机或其他接入设备发送给我们的信息。</p>
		<p>（2）您将第三方管理的账户与您的Basal测评账户相关联，并授权Basal测评访问此类信息，表示您同意Basal测评在遵守本政策的前提下，收集、存储、使用和分享此类信息。我们通过此种途径收到的信息因具体提供的网站而异，并受提供信息的网站控制。</p>
		<p>（3）我们还可能会以其他方式收集有关您的其他信息，如通过您与我们的客户服务团队联系收集您的信息、当您对调查做出答复时收集相关结果。</p>
		<p>（4）我们将收集的信息储存于企业购买的阿里云服务器中，并参照最新相关法律规定的期限动态调整最短和最长储存期限，超过期限后我们将不再储存相关信息（其它协议特殊说明的除外）。</p>
		<p>5.在法律法规允许的范围内，我们可能会在以下情形中收集并使用您的个人信息无需征得您的授权同意：</p>
		<p>（1）与我们履行法律法规规定的义务相关的情形；</p>
		<p>（2）与国家安全、国防安全直接相关的；</p>
		<p>（3）与公共安全、公共卫生、重大公共利益直接相关的；</p>
		<p>（4）与犯罪侦查、起诉、审判和判决执行直接相关的；</p>
		<p>（5）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</p>
		<p>（6）所收集的个人信息是您自行向社会公众公开的情形；</p>
		<p>（7）根据您要求签订和履行合同所必需的；</p>
		<p>（8）从合法公开披露的信息中收集个人信息情形，合法的新闻报道、政府信息公开渠道；</p>
		<p>（9）用于维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；</p>
		<p>（10）法律法规规定的其他情形。</p>
		
		
		
		<p><strong>二、我们可能如何使用您的个人信息</strong></p>
		<p>如上所述，基于国家相关法律法规的规定，以及为向您提供服务及提升服务质量，向您提供安全、顺畅、高效和个性化的体验的目的(包括支持我们开发新产品或完善已有产品功能，为您提供和推荐更为优质或适合的服务)，您同意我们可能将收集的您的相关信息用作下列用途：</p>
		<p>1.向您提供Basal测评平台服务；</p>
		<p>2.基于国家相关主管部门的要求向相关部门进行报告；</p>
		<p>3.在您使用Basal测评服务时，我们将您的信息用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；</p>
		<p>4.帮助我们设计新产品及服务，改善我们现有服务，提升服务质量；</p>
		<p>5.评估Basal测评服务中的相关广告和其他促销及推广活动的效果，并加以改善；</p>
		<p>6.开展市场调查与信息数据分析；</p>
		<p>7.软件认证或管理软件升级；</p>
		<p>8.邀请您参与有关我们产品和服务的调查；</p>
		<p>9.经您授权的其他用途。</p>
		<p>为了您能享受到更好的体验、改善我们的服务或您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某一项服务所收集的信息，以汇集信息或者个性化的方式，用于我们的其他服务。在您使用我们的一项服务时所收集的信息，可能在另一项服务中用于向您提供特定内容，或向您展示与您相关的、非普遍推送的信息。当我们要将信息用于本政策未载明的其它用途时，会事先征求您的同意。当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</p>
		<p>Basal测评可能从第三方获取您授权共享的账户信息（头像、昵称），并在您同意本隐私政策后将您的第三方账户与您的Basal测评手机用户绑定，使您可以通过第三方账户直接登录并使用Basal测评的产品与/或服务。此外，Basal测评可能通过第三方获取您的设备手机号信息和地理位置信息。Basal测评会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。</p>
		<p><strong>三、我们如何使用Cookie和同类技术</strong></p>
		<p>（一）Cookie</p>
		<p>为确保您正常使用Basal测评的服务，我们可能会保存您的用户登录状态并且为您分配一个或多个“Cookie”(一个小文本文件)。当您访问一个需要用户登录才可以提供的信息或服务，用户登录时，我们会把该用户的登录状态加密存储在用户移动端的Cookie文件中。</p>
		<p>Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，可能帮助您省去为使用Basal测评服务而重复登录。</p>
		<p>我们不会将 Cookie用于本政策所述目的之外的任何用途。</p>
		<p><strong>四、我们可能如何委托处理、共享、转让、公开披露您的个人信息</strong></p>
		<p>（一）委托处理</p>
		<p>我们将可能委托第三方在已征得您同意的范围内或者法律法规允许的范围内处理您的个人信息。我们将对委托行为进行个人信息安全影响评估，确保受托第三方达到法律法规要求的数据安全能力要求。我们将对受托第三方监督，通过合同等方式约定受托第三方的责任和义务，并对受托第三方进行审计。我们会准确记录和保存委托处理的您的个人信息的情况。</p>
		<p>（二）共享</p>
		<p>我们依国家相关法律法规及本政策的约定保护和存储您的信息 ，不会将您的个人信息转让给任何公司、组织和个人，</p>
		<p>我们向合作伙伴及第三方共享您的个人信息的情形逐项列举，明细如下：</p>
		<p>1.我们可能与合作伙伴合作举办营销活动，并与其分享活动过程中所产生的、为完成活动所必要的一些信息，以便您能参与活动、我们的合作伙伴能及时与您联系、发放奖品等。</p>
		<p>2.我们的实时消息推送服务将由极光推送提供。我们会与极光渠道共享您的设备平台、设备厂商、设备品牌、设备识别码（IMEI、MAC、IDFA）设备信息，网络信息（IP地址、WIFI信息）以及位置相关信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的信息，并且只会共享提供服务所必要的信息，如果您不同意提供以上信息，将无法使用此类通知和实时消息服务功能。</p>
		<p>3.当您需要向Basal测评的合作机构共享您在Basal测评的个人信息，Basal测评会在征得您的明示授权同意后向您指定的机构提供。</p>
		<p>4.根据法律法规的规定、有权机关的要求或是经您同意。</p>
		<p>5.您与Basal测评关于信息分享的其他约定。</p>
		<p>（三）转让</p>
		<p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
		<p>1.事先获得您明确的同意或授权；</p>
		<p>2.根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；</p>
		<p>3.在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。</p>
		<p>（四）公开披露</p>
		<p>我们仅会在以下情况下，且采取符合业界标准的安全防护措施前提下，才会公开披露您的个人信息</p>
		<p>1.根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</p>
		<p>2.请您理解，根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。</p>
		<p>（五）共享、转让、公开披露个人信息时事先征得授权同意的例外</p>
		<p>根据相关法律法规、监管要求等规定，以下情形中遇到国家有权机关或者监管机关强制性要求的，或者出于对您的权利、权益进行充分保护的目的，或者此处约定的其他合理情形的，Basal测评可能会共享、转让、公开披露用户信息无需事先征得您授权同意：</p>
	
		<p>1.与我们履行法律法规规定的义务相关的情形；</p>
		<p>2.与国家安全、国防安全直接相关的；</p>
		<p>3.与公共安全、公共卫生、重大公共利益直接相关的；</p>
		<p>4.与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
		<p>5.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
		<p>6.个人信息主体自行向社会公众公开的个人信息；</p>
		<p>7.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
		<p>根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</p>
		<p><strong>五、我们如何保护和存储您的个人信息</strong></p>
		<p>1.我们在法律法规相关规定要求的时限内收集和存储您的信息，并依法对这些信息进行严格保密。</p>
		<p>2.为了保障您的信息安全，Basal测评会在现有技术水平下使用符合业界标准的安全防护措施保护您提供的个人信息，采取物理、技术和行政管理安全措施来降低丢失、误用、非授权访问、披露和更改的风险，尽力防止数据遭到未经授权的访问、公开披露、使用、修改、损坏或丢失，包括但不限于传输层数据加密、防火墙和加密存储、物理访问控制、信息访问授权控制、去标识化处理等。为此我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问用户信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护用户信息重要性的认识。</p>
		<p>3. 当Basal测评服务发生停止运营的情形时，我们将以通知、公告形式通知您，同时停止相关产品或服务对您用户信息的收集操作，保护您的用户信息安全。如因技术故障、网络攻击、自然灾害及事故、人为因素各种原因造成全部或部分Basal测评服务中断，Basal测评将采取应急处置和恢复措施予以应对，尽快恢复服务。</p>
		<p>4.Basal测评会采取一切合理可行的措施，确保未收集与您所办理业务无关的个人信息。</p>
		<p>5.您在使用Basal测评服务时，请妥善保管好您的Basal测评账号及其相关密码，我们会通过您的账号及其相应密码来识别您的身份。如您发现您的账号及其相应密码因任何原因已经或将受到泄漏时，您应该立即和我们取得联系，但在我们知悉此种情况和采取行动前，我们对此不负任何责任。</p>
		<p>6.请您理解，由于各种因素有可能出现Basal测评无法合理预见、防范、避免、控制的意外情况。互联网并非绝对安全的环境，请使用复杂密码，协助Basal测评保证您的账号安全。</p>
		<p>7.您可以随时登录自己的账户查看或根据系统要求修改您的账户设置信息，但出于安全性和身份识别方面的考虑，身份证件敏感信息可能进行部分掩码隐藏，同时，您可能无法自主修改注册或激活时提供的某些信息。</p>
		<p>若发生个人信息安全事件，我们将按照法律法规的要求，及时通过适当的方式向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们将及时将事件相关情况以电话、短信或推送通知方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。</p>
		<p>如有必要，我们将公开数据安全和个人信息保护相关的合规证明。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
		<p>8.我们将按照法律规定和与您约定的期限储存您的个人信息。超过该期限的，除获得您的授权外，我们将对您的个人信息进行删除或匿名化处理。</p>
		<p>9.如我们停止运营，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。</p>
		<p><strong>六、您的权利</strong></p>
		<p>Basal测评非常重视您对个人信息的关注，并尽全力保护您对于您个人信息查询、更正、注销账户的权利，以使您拥有充分的能力保障您的隐私和安全。我们将在15个工作日内受理并处理您的请求。您的权利包括：</p>
		<p>1.查询和更正您的个人信息</p>
		<p>除法律法规规定外，您有权随时访问下述您的个人信息并随时更正其中的部分信息，具体包括：</p>
		<p>（1）您的账户信息</p>
		<p>个人信息：我的-头像图标处</p>
		<p>账户信息：我的-个人信息</p>
		<p>2.改变您授权同意的范围或撤回您的授权</p>
		<p>每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以根据手机的不同品牌和型号，通过开通或关闭位置服务权限、读取联系人权限、拨打电话权限等，随时给予或撤销您的授权同意。</p>
		<p>请您注意，您自主注销Basal测评服务的同时，将视同您撤回了对Basal测评服务《隐私政策》的同意。当您收回同意后，Basal测评将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。</p>
		<p>4.尽管有上述约定，但按照法律法规要求，在以下情形下，我们可能无法响应您的请求：</p>
		<p>（1）与国家安全、国防安全直接相关的；</p>
		<p>（2）与公共安全、公共卫生、重大公共利益直接相关的；</p>
		<p>（3）与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
		<p>（4）有充分证据表明您存在主观恶意或滥用权利的；</p>
		<p>（5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
		<p>（6）涉及商业秘密的。</p>
		<p><strong>七、我们如何处理未成年人的个人信息</strong></p>
		<p>如果没有监护人的同意，未成年人不得创建自己的用户账户。如您为未成年人，请您的监护人仔细阅读本指引，并在征得您的监护人同意的前提下使用我们的服务或向我们提供信息。我们只会在法律法规及监管要求允许或者保护未成年人所必要的情况下使用、对外提供此信息。</p>
		<p><strong>八、本政策如何更新</strong></p>
		<p>1.为给您提供更好的服务并随着Basal测评业务的调整、发展，本政策也会随之更新。我们会通过在Basal测评上发出更新版本并在生效前通过公告或以其他适当方式提醒您相关内容的更新。本政策的修订可能会造成您在本政策下权利的实质减少，您知悉并确认，如您不同意更新后的内容，应立即停止使用相应服务，并注销相关的用户；若您继续使用我们提供的服务，视为您同意受经修订的本政策的约束。</p>
		<p>2.您可以在Basal测评，通过登录时查看本政策。我们鼓励您在每次使用Basal测评服务或/和产品时都查阅我们的隐私政策。</p>
		<p><strong>九、如何联系我们</strong></p>
		<p>1.如您对本政策存在任何疑问，或任何相关的信息安全投诉、使用建议，请联系我们的专属客服，我们将在15个工作日内协助您处理完成</p>
		<p>2.如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向Basal测评住所地有管辖权的人民法院提起诉讼来寻求解决方案。</p>
		<p><strong>十、本政策适用及其他</strong></p>
		<p>1.如果您在使用Basal测评服务过程中还会使用到第三方的产品或服务，请您遵守第三方的相关隐私政策或规定。</p>
		<p>2.本政策所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本政策涵义解释的依据。</p>
		<p>3.本政策部分条款无效或不可执行的，不影响其余条款的效力，其余条款对各方仍具有约束力。</p>
	
	</p>`,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  font-size: 28rpx;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  padding: 24rpx;
}
</style>
